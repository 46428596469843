// This page was create from HomePage.jsx

import React, { Component , Fragment } from "react";
// import Slider from "react-slick";
// import {Link} from "react-router-dom";
// import { slickDot } from "../page-demo/script";
// import Scrollspy from 'react-scrollspy';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp  } from "react-icons/fi";
// import { FiX , FiMenu } from "react-icons/fi";
import HeaderMain from "../component/header/HeaderMain"; // Customized
// import ServiceList from "../elements/service/ServiceList";
// import CounterOne from "../elements/counters/CounterOne";
// import Testimonial from "../elements/Testimonial";
// import Team from "../elements/Team";
// import BlogContent from "../elements/blog/BlogContent";
// import BrandTwo from "../elements/BrandTwo";
import FooterTwo from "../component/footer/FooterTwo";
// import Contact from "../elements/contact/ContactTwo";
import Helmet from "../component/common/Helmet";

import TabFour from "../elements/tab/TabFour";


// const SlideList = [
//     {
//         textPosition: 'text-center',
//         category: '',
//         title: 'iTTaylor ',
//         description: 'Innovative and custom solutions offer value for our clients by increasing income, decreasing costs, and reducing risk.',
//         buttonText: 'Contact Us',
//         buttonLink: '/contact'
//     }
// ]

// const list = [
//     {
//         image: 'image-1',
//         category: 'Development',
//         title: 'Getting tickets to the big show'
//     },
//     {
//         image: 'image-2',
//         category: 'Development',
//         title: 'Getting tickets to the big show'
//     },
//     {
//         image: 'image-3',
//         category: 'Development',
//         title: 'Getting tickets to the big show'
//     },
//     {
//         image: 'image-4',
//         category: 'Development',
//         title: 'Getting tickets to the big show'
//     },
//     {
//         image: 'image-3',
//         category: 'Development',
//         title: 'Getting tickets to the big show'
//     },
//     {
//         image: 'image-4',
//         category: 'Development',
//         title: 'Getting tickets to the big show'
//     }
// ]

class AboutUsPage extends Component{
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);

       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded');
        })
    }

    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }

    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }

    stickyHeader () {}

    render(){
        // const PostList = BlogContent.slice(0 , 5);
        
        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            }else{
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }

        const title = 'About Us';
        // Todo: add paragraph tag to the description var
        const description = <>
            <p> ITTaylor uses cutting-edge technologies, a trustworthy process, and prime design and development tools. As a result, there is an increase in efficiency, innovation, and consumer satisfaction.</p>
            <p>Planning is done following the 4D process (<span style={{fontWeight: '500'}}>define, design, develop, deliver</span>). In terms of strategy, we employ agile development, which enables quick feedback and the capacity to adapt to change. </p>
        </>

        return(
            <Fragment>

                <Helmet pageTitle="About Us" />

                <HeaderMain  homeLink='about'/>

                {/* Start About Area */}
                {/* style={{ height: "calc(100vh - 110px" }} */}
                <div id="about" className="fix fullscreen-page"  >
                    <div className="about-area ptb--120  bg_color--1">
                        <div className="about-wrapper">
                            <div className="container">
                                <div className="row row--35 align-items-center">
                                    <div className="col-lg-5">
                                        <div className="thumbnail">
                                            <img className="w-100" src="/assets/images/about/about-20.jpg" alt="About Images"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-7">
                                        <div className="about-inner inner">
                                            <div className="section-title">
                                                <h2 className="title">{title}</h2>
                                                <p className="description">{description}</p>
                                            </div>
                                            <div className="row mt--30">
                                                <TabFour tabStyle="tab-style--1" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                {/* End About Area */}


                {/* Todo: remove this div */}
                {/* <div style={{minHeight: "100px"}}></div> */}

                
                {/* Start Footer Style  */}
                    <FooterTwo />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160} >
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

            </Fragment>
        )
    }
}

export default AboutUsPage;