import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageTitle} || iTTaylor </title>
                    {/* Todo: Change meta content */}
                    <meta name="description" content="iTTaylor – We help businesses, agencies and start-ups to bring their ideas to life turning their requirements into high-quality web solutions." />
                </Helmet>
            </React.Fragment>
        )
    }
}

export default PageHelmet;
