import React ,{ Component }from "react";
// import { FiCast , FiLayers , FiUsers , FiMonitor } from "react-icons/fi";
import { FaLayerGroup, FaDatabase, FaChartBar } from "react-icons/fa";
// import {  FaLaptopCode,  FaChartPie    } from "react-icons/fa";

const ServiceList = [
    {
        icon: <FaLayerGroup />,
        title: 'Website development',
        description: 'We make your business stand out and become the primary go-to resource for users seeking your products, current information and assistance.'
    },
    {
        icon: <FaDatabase />,
        title: 'Database development',
        description: 'We design and optimize specialized database systems that keep information structured, secure, and convenient for authorized users.'
    },
    {
        icon: <FaChartBar />,
        title: 'Analytics',
        description: 'We help you to understand your consumers, improve customer targeting and develop better problem-solving solutions through data analysis.'
    },
    // { 
    //     icon: <FiMonitor />,
    //     title: 'Mobile App Development',
    //     description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    // },
    // {
    //     icon: <FiUsers />,
    //     title: 'Marketing & Reporting',
    //     description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    // },
    // { 
    //     icon: <FiMonitor />,
    //     title: 'Mobile App Development',
    //     description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    // }
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column} text-center`} key={i}>
                            <a href="/#service"> {/* href="/service-details" */}
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
