// This component was created from component TabTwo.jsx

import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FaReact, FaWordpress, FaNodeJs, FaSass, FaSlack, FaBootstrap, FaGithub} from 'react-icons/fa';
import { GrMysql, GrOracle } from "react-icons/gr";
import { SiJson, SiJquery, SiTailwindcss, SiAsana, SiW3C, SiAdobecreativecloud } from "react-icons/si";
// import { SiBootstrap } from "react-icons/si";


class TabFour extends Component{
    render(){
        const 
            tab1 = "Technologies",
            tab2 = "Tools";
            // tab3 = "Experience",
            // tab4 = "Education & Certification";

        const { tabStyle } = this.props;
        const iconSize = 34,
            iconColor='';

        return(
            <div className="w-100" >

                {/* Start Tabs Area */}
                <div className="tabs-area" >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        {/* <Tab>{tab3}</Tab> */}
                                        {/* <Tab>{tab4}</Tab> */}
                                    </TabList>
                                    {/* style={{ fontWeight: 'bold', flexWrap: 'wrap' }} */}
                                    <TabPanel>
                                        <div className="single-tab-content d-flex"  >
                                            <div style={{ flex: '50%' }} >
                                                <ul>
                                                    <li><span className="mr--10"><FaReact size={ iconSize } color={ iconColor } /></span> React.js </li>
                                                    <li><span className="mr--10"><GrMysql size={ iconSize } color={ iconColor } /></span> MySQL </li>
                                                    <li><span className="mr--10"><GrOracle size={ iconSize } color={ iconColor } /></span> Oracle </li>
                                                    {/* <li><span><FaReact size={ iconSize } /></span> TypeScript </li> */}
                                                    <li><span className="mr--10"><SiJson size={ iconSize } color={ iconColor } /></span> JSON </li>
                                                </ul>
                                            </div>
                                            <div style={{ flex: '50%' }}>
                                                <ul>
                                                    <li><span className="mr--10"><SiJquery size={ iconSize } color={ iconColor } /></span> jQuery </li>
                                                    <li><span className="mr--10"><FaNodeJs size={ iconSize } color={ iconColor } /></span> Node.js </li>
                                                    <li><span className="mr--10"><FaWordpress size={ iconSize } color={ iconColor } /></span> WordPress </li>
                                                    {/* <li><span><FaReact size={ iconSize } /></span> Web Forms </li> */}
                                                </ul>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content d-flex">
                                       <div style={{ flex: '50%' }} >
                                                <ul>
                                                    <li><span className="mr--10"><FaSass size={ iconSize } color={ iconColor } /></span>SaSS</li>
                                                    <li><span className="mr--10"><SiTailwindcss size={ iconSize } color={ iconColor } /></span>Tailwind</li>
                                                    <li><span className="mr--10"><SiAsana size={ iconSize } color={ iconColor } /></span>Asana</li>
                                                    {/* <li><span><FaReact size={ iconSize } /></span> TypeScript </li> */}
                                                    <li><span className="mr--10"><FaSlack size={ iconSize } color={ iconColor } /></span>Slack</li>
                                                </ul>
                                            </div>
                                            <div style={{ flex: '50%' }}>
                                                <ul>
                                                    <li><span className="mr--10"><SiAdobecreativecloud size={ iconSize } color={ iconColor } /></span>Adobe Creative Cloud</li>
                                                    <li><span className="mr--10"><SiW3C size={ iconSize } color={ iconColor } /></span>World Wide Web Consortium</li>
                                                    <li><span className="mr--10"><FaBootstrap size={ iconSize } color={ iconColor } /></span>Bootstrap</li>
                                                    <li><span className="mr--10"><FaGithub size={ iconSize } color={ iconColor } /></span>GitHub</li>
                                                </ul>
                                            </div>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="/service">Sr. Front-end Engineer<span> - Google</span></a> 2018 - Current
                                               </li>
                                               <li>
                                                   <a href="/service">Front-end Engineer<span> - Microsoft</span></a> 2017 - 2018
                                               </li>
                                               <li>
                                                   <a href="/service">Software Engineer<span> - Alibaba </span></a> 2013- 2014
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="/service">BSc In CSE<span> - ABC University, Los Angeles, CA</span></a> 2010
                                               </li>
                                               <li>
                                                   <a href="/service">Diploma in Computer Science<span> - Gamma Technical Institute</span></a> 2009
                                               </li>
                                               <li>
                                                   <a href="/service">Graphic Designer<span> - ABC Institute, Los Angeles, CA</span></a> 2007
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>
                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabFour;